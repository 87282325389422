import { createContext } from 'react';
import { AllProductInfoType, ProductPromoData } from 'utils/types';

export type ComparisonModalProps = {
  comparisonModalOpen: boolean;
  handleComparisonModalClick: React.MouseEventHandler<HTMLButtonElement> &
    ((e: React.SyntheticEvent<HTMLElement, Event>) => void);
};

export const ProductInfoContext = createContext<AllProductInfoType>(
  {} as AllProductInfoType,
);
export const AllProductPromosContext = createContext<ProductPromoData[]>([]);
export const ComparisonModalContext = createContext({} as ComparisonModalProps);
