import { Accordion, Col, Container } from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import parse from 'html-react-parser';
import React from 'react';
import { sanitize } from 'utils/domHelpers';
import { FAQ } from 'utils/types';

import * as S from './styles';

export interface FAQSectionProps {
  faqs: FAQ[];
  title?: string;
  handleFAQClick?: Function;
  clickedFaqId?: string;
}

export const FAQSection: React.FC<FAQSectionProps> = ({
  faqs,
  // Provide a default to preserve the interface, just in case.
  // TODO: remove the default if we are confident we don't have any
  // uses of this component that don't supply a title..
  title = 'Questions?',
  handleFAQClick = () => {},
  clickedFaqId,
}) =>
  faqs.length > 0 ? (
    <SectionWrapper id="faq" data-testid="faqSection">
      <Container>
        <S.Row>
          <S.SectionHeader>{title}</S.SectionHeader>
        </S.Row>
        <S.Row>
          <Col>
            {faqs.map((faq, index) => (
              <Accordion.Wrapper key={index} openChild={clickedFaqId}>
                <Accordion.Item id={`question-${index + 1}`}>
                  <S.Heading
                    onClick={handleFAQClick(
                      faq.question,
                      `accordion-heading-question-${index + 1}`,
                    )}
                  >
                    <S.Title>{faq.question}</S.Title>
                  </S.Heading>
                  <S.Child>
                    <S.Content>{parse(sanitize(faq.answer))}</S.Content>
                  </S.Child>
                </Accordion.Item>
              </Accordion.Wrapper>
            ))}
          </Col>
        </S.Row>
      </Container>
    </SectionWrapper>
  ) : null;

export default FAQSection;
