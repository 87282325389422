import React, { useContext } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { ProductInfoContext } from 'utils/helpers/pdpContexts';

import { FAQSection } from '../../components/FAQSection';

export interface FAQContainerProps {
  content: {
    title: string;
    digDeeperSections?: Array<{ question: string; answer: { answer: string } }>;
  };
  clickedFaqId?: string;
  setClickedFaqId?: (faq: string) => void;
}
export const FAQContainer: React.FC<FAQContainerProps> = ({
  content,
  clickedFaqId,
  setClickedFaqId,
}) => {
  if (!content) {
    return null;
  }

  // content.digDeeperSections is used in the category page
  const questionAnswer =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useContext(ProductInfoContext).digDeeperSections ||
    content.digDeeperSections;

  const handleFAQClick = (question: string, faqId: string) => () => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.FAQ_CLICK,
      data: { question },
    });
    // If the FAQ accordion is already opened, it will close
    // when the user clicks on the same FAQ again
    if (faqId === clickedFaqId && setClickedFaqId) {
      setClickedFaqId('');
    }
  };

  if (!questionAnswer || !content) return null;
  const { title } = content;

  const props = {
    faqs: questionAnswer.map((section) => ({
      question: section.question,
      answer: section.answer.answer,
    })),
    title,
    handleFAQClick,
    clickedFaqId,
  };

  return <FAQSection {...props} />;
};

export default FAQContainer;
