import {
  typography,
  size,
  mediaQueries,
  Accordion,
  colors,
} from '@everlywell/leaves';
import styled from 'styled-components';

const Heading = styled(Accordion.Heading)`
  padding: ${size.md}px 0;
  border-top: 1px solid ${colors.gray2};
`;

const Title = styled.span`
  ${typography.buttonTextSmall};
  text-align: left;

  + div {
    color: ${colors.green5};
  }
`;

const Child = styled(Accordion.Child)`
  ${typography.bodyText}
  padding-bottom: ${size.lg}px;

  a {
    text-decoration: none;
    ${typography.buttonText};
    color: ${colors.green5};
    font-weight: ${typography.weight.book};
  }

  ol,
  ul {
    li {
      display: list-item;
      list-style-position: inside;

      ul {
        margin-left: ${size.md}px;
      }
    }
  }
`;

const Content = styled.div`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${size.md}px;
  }
`;

const SectionHeader = styled.h2`
  ${typography.h3Text}
  text-align: center;
  margin-bottom: ${size.xl2}px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xl3}px;
  }
`;

const Row = styled.div`
  justify-content: center;
  width: 100%;
  max-width: 54rem;
  margin: 0 auto;
  &:last-of-type {
    border-bottom: 1px solid ${colors.gray2};
  }
`;

export { Title, Heading, Child, Content, SectionHeader, Row };
